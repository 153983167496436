import { isNonEmptyString } from '@wise/utils'
import { t } from 'i18next'
import NextHead from 'next/head'
import * as React from 'react'

import { PORTAL_RUNTIMES } from '~shared/config/app'
import { PortalMode, useMode } from '~shared/hooks/useMode'

interface Props {
  title?: string
}

const getTabTitle = (mode: PortalMode) => {
  if (PORTAL_RUNTIMES.includes('NETWORK')) return t('portal.NAP')

  switch (mode) {
    case 'WAP':
      return t('portal.WAP')
    case 'MCP':
      return t('portal.MCP')
    case 'NAP':
      return t('portal.NAP')
    case 'undetermined':
      return t('generic.loading', 'Loading...') // Fallback incase i18n is not loaded
  }
}

const Head: React.FC<Props> = ({
  title: customTitle,
  children,
}): JSX.Element => {
  const mode = useMode()

  const title = React.useMemo(() => {
    return [customTitle, getTabTitle(mode)].filter(isNonEmptyString).join(' | ')
  }, [customTitle, mode])

  return (
    <NextHead>
      <title key='title'>{title}</title>
      {children}
    </NextHead>
  )
}

export default Head
