import { djs } from '@wise/utils'
import * as React from 'react'

import { type MaintenanceModeProps } from '~shared/components/MaintenanceMode/MaintenanceMode'
import useInterval from '~shared/hooks/useInterval'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { isServerSide } from '~shared/services/context'
import { trpc } from '~shared/services/trpc'
import { Time } from '~shared/utils/Time'

type PendingMaintenanceResult = {
  status: 'loading' | 'error'
  config?: never
  overriden?: never
}
type FinalMaintenanceResult = {
  status: 'in-maintenance' | 'live'
  config: MaintenanceModeProps['config']
  overriden?: boolean
}

export type UseMaintenanceModeResult =
  | PendingMaintenanceResult
  | FinalMaintenanceResult

const hasMaintenanceModeOverride = () => {
  try {
    if (isServerSide()) return false
    const value = window.localStorage.getItem('mcp_maintenance_override')
    return value === 'override'
  } catch (error) {
    leaveBreadcrumb('Maintenance mode override check failed')
    notify(error)
    return false
  }
}

export type MaintenanceModeStatus =
  | MaintenanceModeProps['config']
  | 'loading'
  | 'error'

const useMaintenanceMode = (): UseMaintenanceModeResult => {
  const configQuery = trpc.config.maintenanceConfig.useQuery()

  useInterval(Time.seconds(60), () => configQuery.refetch())

  return React.useMemo<UseMaintenanceModeResult>(() => {
    if (configQuery.status === 'error') return { status: 'error' }
    if (configQuery.status === 'loading') return { status: 'loading' }

    const config = configQuery.data.maintenanceMode

    if (hasMaintenanceModeOverride())
      return { status: 'live', config, overriden: true }

    if (config.active) return { status: 'in-maintenance', config }
    const to = config.to ? djs(config.to) : undefined
    const from = config.from ? djs(config.from) : undefined

    if (from && !to && from.isValid() && djs().isAfter(from)) {
      return { status: 'in-maintenance', config }
    }

    if (to && !from && to.isValid() && djs().isBefore(to)) {
      return { status: 'in-maintenance', config }
    }

    if (
      from &&
      to &&
      from.isValid() &&
      to.isValid() &&
      djs().isBetween(from, to)
    ) {
      return { status: 'in-maintenance', config }
    }
    return { status: 'live', config }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configQuery.data?.maintenanceMode,
    configQuery.status,
    configQuery.dataUpdatedAt,
  ])
}

export const MaintenanceModeContext = React.createContext<
  ReturnType<typeof useMaintenanceMode>
>({ status: 'error' })

export const useMaintenanceContext = () =>
  React.useContext(MaintenanceModeContext)

export default useMaintenanceMode
