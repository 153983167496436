import {
  GetInitialUserDataDocument,
  GetInitialUserDataQuery,
} from '@wise/graphql'
import { isDefined } from '@wise/utils'

import { FALLBACK_FEATURE_FLAGS } from '~shared/config/feature-flags'
import { fetchFeatureFlags } from '~shared/featureFlags/api'
import { PortalMode } from '~shared/hooks/useMode'
import { setAnalyticsUser } from '~shared/services/analytics/analytics'
import { setApiMetadata } from '~shared/services/api/api'
import { getApolloClient } from '~shared/services/apollo/apollo'
import { notify, setUser } from '~shared/services/bugsnag/client'
import { FirebaseUser } from '~shared/services/firebase/auth/Authenticator'
import { AuthData } from '~shared/services/firebase/auth/store'
import {
  AuthUserData,
  BaseUserData,
} from '~shared/services/firebase/auth/types'
import { asyncMaybe } from '~shared/utils/maybe'

import { getDriverName } from '@/subcontractors/tables/helpers'

const getAuthUser = (result: GetInitialUserDataQuery): AuthUserData | null => {
  switch (result.me.user.__typename) {
    // These Network users are resolved via the Persona service, and can include different fields from the normal User type
    case 'NetworkUser':
      return {
        __brand: 'network',
        email: result.me.user.email,
        firstName: result.me.user.firstName,
        id: result.me.user.userId,
        lastName: result.me.user.userLastName,
        permissions: [],
        roles: [],
        userPermissions: [],
      }
    case 'User':
      {
        const baseUserData: BaseUserData = {
          email: result.me.user.email,
          firstName: result.me.user.firstName,
          id: result.me.user.id,
          lastName: result.me.user.lastName ?? '',
          permissions: result.me.user.permissions?.filter(isDefined) ?? [],
          roles: result.me.user.roles?.filter(isDefined) ?? [],
          userPermissions: result.me.user.userPermissions ?? [],
        }

        switch (result.me.user.type) {
          case 'NETWORK':
            throw new Error(
              'Invalid user type - Network user cannot be resolved via the "User" __typename!',
            )
          case 'MAINCONTRACTOR': {
            const mainContractor:
              | BrandExtract<AuthUserData, 'main-contractor'>['mainContractor']
              | null =
              result.me.user.gigs?.find((g) => g?.mainContractor)
                ?.mainContractor ?? null

            const mcOnboardingData = result.getMcOnboardingData ?? null

            return {
              ...baseUserData,
              __brand: 'main-contractor',
              mainContractor,
              mcOnboardingData,
            }
          }
          case 'WISE':
            return {
              ...baseUserData,
              __brand: 'wise',
            }
        }
      }

      return null
  }
}

const userBrandToPortalMode = (brand: AuthUserData['__brand']): PortalMode => {
  switch (brand) {
    case 'main-contractor':
      return 'MCP'
    case 'network':
      return 'NAP'
    case 'wise':
      return 'WAP'
  }
}

export const resolveAuthData = async (
  firebaseUser: FirebaseUser | null,
): Promise<AuthData | null> => {
  try {
    if (!firebaseUser) return null

    const result = await getApolloClient().query({
      query: GetInitialUserDataDocument,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    })

    if (!result.data) return null

    const accessToken = await firebaseUser.getIdToken()

    const user = getAuthUser(result.data)

    setUser(user?.id, user?.email, user ? getDriverName(user) : undefined)
    setAnalyticsUser(user)
    setApiMetadata(user)

    if (!user) return null

    const featureFlags = await asyncMaybe(() =>
      fetchFeatureFlags({
        portalMode: userBrandToPortalMode(user.__brand),
        mcId:
          user.__brand === 'main-contractor'
            ? user.mainContractor?.id ?? null
            : null,
        user: {
          id: user.id,
          email: user.email,
        },
      }),
    )

    return {
      accessToken,
      featureFlags: featureFlags ?? FALLBACK_FEATURE_FLAGS,
      user,
    }
  } catch (error) {
    notify(new ResolveAuthDataError(error))
    throw error
  }
}

class ResolveAuthDataError extends Error {
  constructor(public error: unknown) {
    super(String(error), { cause: error })
    this.name = 'ResolveAuthDataError'
  }
}
