import { useAuth, useUser } from '../services/firebase/auth/hooks'

export type MainContractorPortal = 'MCP'
export type WiseAdminPortal = 'WAP'
export type NetworkPortal = 'NAP'

export type PortalMode =
  | MainContractorPortal
  | WiseAdminPortal
  | NetworkPortal
  | 'undetermined'

export const useMode = (): PortalMode => {
  const { resolved } = useAuth()
  const user = useUser()

  if (!resolved) return 'undetermined'

  switch (user?.__brand) {
    case 'network':
      return 'NAP'
    case 'wise':
      return 'WAP'
    case 'main-contractor':
    default:
      return 'MCP'
  }
}
