import { publicRuntimeConfig } from './runtime'

const validAppEnvs = [
  'local',
  'dev',
  'qa',
  'demo',
  'staging',
  'production',
  'unknown',
  'test',
] as const
export type AppEnv = (typeof validAppEnvs)[number]

const validPortalRuntimes = ['MAINCONTRACTOR', 'NETWORK'] as const
export type PortalRuntime = (typeof validPortalRuntimes)[number]

const toAppEnv = (val: string | undefined): AppEnv => {
  if (!val) return 'unknown'
  if (validAppEnvs.includes(val as AppEnv)) return val as AppEnv
  return 'unknown'
}

const toPortalRuntimes = (val: string | undefined): PortalRuntime[] => {
  const rawSplit = val?.split(',') ?? []

  const runtimes = rawSplit.filter<PortalRuntime>((r): r is PortalRuntime =>
    validPortalRuntimes.includes(r as PortalRuntime),
  )

  return runtimes.length === 0 ? ['MAINCONTRACTOR'] : runtimes
}

export const APP_ENV = toAppEnv(publicRuntimeConfig.environment)
export const PORTAL_RUNTIMES = toPortalRuntimes(
  publicRuntimeConfig.portalRuntime,
)
export const DEV_MODE =
  publicRuntimeConfig.developerMode === 'true' ||
  publicRuntimeConfig.developerMode === '1'
