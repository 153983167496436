import { RoutePaths, route } from '~generated/routes'
import { AuthUserData } from '~shared/services/firebase/auth/types'

import { hasPassedKYC } from '../utils/kycStatus'

export const needsOnboardingRoute = (
  mcOnboardingData:
    | BrandExtract<AuthUserData, 'main-contractor'>['mcOnboardingData']
    | undefined,
): RoutePaths | null => {
  if (mcOnboardingData?.mainContractor?.status === 'ACTIVE') return null

  if (
    mcOnboardingData?.mainContractor?.id &&
    mcOnboardingData?.mainContractor.signature?.id
  ) {
    if (!hasPassedKYC(mcOnboardingData.kycStatus)) {
      return route('/onboarding/checking-details')
    }
    return route('/')
  }

  if (
    mcOnboardingData?.mainContractor?.id &&
    !mcOnboardingData?.mainContractor.signature?.id
  ) {
    return route('/onboarding/protecting-your-business')
  }

  return null
}
