import { invariant } from '@wise/utils'

import {
  GatekeeperCheck,
  gatekeeperForUser,
} from '~shared/hooks/gatekeeper/useGatekeeper'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions & {
  check: GatekeeperCheck
}

export const gatekeeper = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    const check = options?.check
    invariant(check, 'Gatekeeper middleware requires a check to be provided')
    return gatekeeperForUser(
      check,
      ctx.auth.data?.user,
      ctx.auth.data?.user.userPermissions ?? null,
    )
      ? { status: 'ok' }
      : { status: 'redirect', url: options?.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
