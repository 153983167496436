import * as React from 'react'

import { Time } from '~shared/utils/Time'

const useInterval = (time: Time, callback: () => void) => {
  const callbackRef = React.useRef(callback)
  React.useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  React.useEffect(() => {
    const interval = setInterval(() => {
      callbackRef.current()
    }, time.toMilliseconds())
    return () => clearInterval(interval)
  }, [time])
}

export default useInterval
