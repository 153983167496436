import { RoutePaths, route } from '~generated/routes'
import { AuthStore } from '~shared/services/firebase/auth/store'
import { isBranded } from '~shared/utils/brand'

import { needsOnboardingRoute } from '@/mc-onboarding/middleware/MCOnboarding'

type NextRouteOptions = {
  authState: AuthStore
  requestedUrl: string
}

const NOT_ALLOWED_REDIRECTS = [
  '/',
  '/login',
  '/logout',
  '/auth/change-password',
]

export const getNextRoute = ({
  authState,
  requestedUrl,
}: NextRouteOptions): RoutePaths | string | undefined => {
  const user = authState.data?.user
  const mcOnboardingData =
    user && isBranded(user, 'main-contractor') ? user.mcOnboardingData : null
  const onboardingRoute = needsOnboardingRoute(mcOnboardingData)
  if (onboardingRoute !== null) return onboardingRoute

  if (user) {
    return '/'
  }

  const param = !NOT_ALLOWED_REDIRECTS.includes(requestedUrl)
    ? `?path=${encodeURIComponent(requestedUrl)}`
    : ''
  return `${route('/login')}${param}`
}
