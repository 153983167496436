import { isDefined } from '@wise/utils'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const auth = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    return isDefined(ctx.auth.data?.user)
      ? { status: 'ok' }
      : { status: 'redirect', url: options?.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
