import { UserType } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'
import { castArray } from 'lodash'
import * as React from 'react'

import { UserPermission } from '~shared/permissions/enum'
import {
  useUser,
  useUserPermissions,
} from '~shared/services/firebase/auth/hooks'
import { AuthUserData } from '~shared/services/firebase/auth/types'

import { RoleType } from './roles'

type LegacyPermissionType = `${string}.${string}.${string}`

type UserTypeChecker = { userType: UserType | { NOT: UserType } }

type RoleChecker =
  | { role: RoleType | { NOT: RoleType } }
  | {
      roles: RoleType | RoleType[] | { NOT: RoleType | RoleType[] }
    }

type LegacyPermissionChecker =
  | { legacyPermission: LegacyPermissionType | { NOT: LegacyPermissionType } }
  | {
      legacyPermissions:
        | LegacyPermissionType
        | LegacyPermissionType[]
        | { NOT: LegacyPermissionType | LegacyPermissionType[] }
    }

type UserPermissionChecker =
  | {
      userPermission: UserPermission | { NOT: UserPermission }
    }
  | {
      userPermissions:
        | UserPermission
        | UserPermission[]
        | { NOT: UserPermission | UserPermission[] }
    }

export type GatekeeperCheck =
  | { NOT: GatekeeperCheck }
  | { AND: GatekeeperCheck[] }
  | { OR: GatekeeperCheck[] }
  | UserTypeChecker
  | RoleChecker
  | LegacyPermissionChecker
  | UserPermissionChecker

type GatekeeperOptions = {
  userType: UserType | undefined
  check: GatekeeperCheck
  roles: string[]
  legacyPermissions: string[]
  userPermissions: UserPermission[]
}

/**
 * This allows us to perform highly-granular permissions checks on a list of roles/permissions.
 * We can combine operators to construct highly-complex (or super simple!) checks.
 * Check the tests in `useGatekeeper.test.ts` for examples.
 */
export const gatekeeper = ({
  check,
  userType,
  roles,
  legacyPermissions,
  userPermissions,
}: GatekeeperOptions): boolean => {
  if ('NOT' in check)
    return !gatekeeper({
      check: check.NOT,
      userType,
      roles,
      legacyPermissions,
      userPermissions,
    })
  if ('AND' in check)
    return check.AND.every((c) =>
      gatekeeper({
        check: c,
        userType,
        roles,
        legacyPermissions,
        userPermissions,
      }),
    )
  if ('OR' in check)
    return check.OR.some((c) =>
      gatekeeper({
        check: c,
        userType,
        roles,
        legacyPermissions,
        userPermissions,
      }),
    )

  if ('userType' in check) {
    if (typeof check.userType !== 'string' && 'NOT' in check.userType) {
      return userType !== check.userType.NOT
    } else {
      return userType === check.userType
    }
  }

  if ('roles' in check || 'role' in check) {
    const roleList = 'roles' in check ? check.roles : check.role
    if (typeof roleList !== 'string' && 'NOT' in roleList) {
      return castArray(roleList.NOT).every((r) => !roles.includes(r))
    } else {
      return castArray(roleList).every((r) => roles.includes(r))
    }
  }

  if ('userPermissions' in check || 'userPermission' in check) {
    const userPermissionsList =
      'userPermissions' in check ? check.userPermissions : check.userPermission
    if (
      typeof userPermissionsList !== 'string' &&
      'NOT' in userPermissionsList
    ) {
      return castArray(userPermissionsList.NOT).every(
        (u) => !userPermissions.includes(u),
      )
    } else {
      return castArray(userPermissionsList).every((u) =>
        userPermissions.includes(u),
      )
    }
  }

  const legacyPermissionList =
    'legacyPermissions' in check
      ? check.legacyPermissions
      : check.legacyPermission
  if (
    typeof legacyPermissionList !== 'string' &&
    'NOT' in legacyPermissionList
  ) {
    return castArray(legacyPermissionList.NOT).every(
      (p) => !legacyPermissions.includes(p),
    )
  }
  return castArray(legacyPermissionList).every((p) =>
    legacyPermissions.includes(p),
  )
}

const toUserType = (type: AuthUserData['__brand']): UserType => {
  switch (type) {
    case 'main-contractor':
      return 'MAINCONTRACTOR'
    case 'network':
      return 'NETWORK'
    case 'wise':
      return 'WISE'
  }
}

export const gatekeeperForUser = (
  check: GatekeeperCheck,
  user: AuthUserData | undefined,
  userPermissionsList: AuthUserData['userPermissions'] | null,
) => {
  const userType = user ? toUserType(user.__brand) : undefined
  const roles = user?.roles?.map((r) => r?.id).filter(isNonEmptyString) ?? []
  const legacyPermissions = user?.permissions?.filter(isNonEmptyString) ?? []
  const userPermissions = userPermissionsList ?? []

  return gatekeeper({
    userType,
    check,
    roles,
    legacyPermissions,
    userPermissions,
  })
}

const useGatekeeper = (check: GatekeeperCheck) => {
  const user = useUser()
  const userPermissionsList = useUserPermissions()

  return React.useMemo(
    () => gatekeeperForUser(check, user, userPermissionsList),
    [user, check, userPermissionsList],
  )
}

export const useGatekeeperCall = () => {
  const user = useUser()
  const userPermissionsList = useUserPermissions()

  return React.useCallback(
    (check: GatekeeperCheck) =>
      gatekeeperForUser(check, user, userPermissionsList),
    [user, userPermissionsList],
  )
}

export default useGatekeeper
