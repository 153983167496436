import { ParsedFeatureFlags } from '@wise/kv'

import { PortalMode } from '~shared/hooks/useMode'
import { callLocalAPI } from '~shared/services/api/api'

type FeatureFlagOptions = {
  portalMode: PortalMode
  mcId: string | null
  user: {
    id: string
    email: string
  }
}

export const fetchFeatureFlags = async ({
  portalMode,
  mcId,
  user,
}: FeatureFlagOptions): Promise<ParsedFeatureFlags> => {
  const search = new URLSearchParams({
    mcId: mcId || '',
    portalMode,
    userId: user.id,
    userEmail: user.email,
  }).toString()
  const response = await callLocalAPI<ParsedFeatureFlags>(
    `/api/feature-flags?${search}`,
  )

  if (!response.ok) {
    throw response.error
  }

  return response.data as ParsedFeatureFlags
}
