import {
  ApiErrorResponseFormat,
  ApiSuccessResponseFormat,
} from '~shared/api/api'
import { leaveBreadcrumb } from '~shared/services/bugsnag/client'
import { AuthUserData } from '~shared/services/firebase/auth/types'

import { getDriverName } from '@/subcontractors/tables/helpers'

interface MetadataType {
  userId?: string
  userName?: string
  userEmail?: string
}

let metadata: MetadataType = {}

export const setApiMetadata = (
  user: Pick<AuthUserData, 'id' | 'firstName' | 'lastName' | 'email'> | null,
): void => {
  metadata.userId = user?.id ?? undefined
  metadata.userName = user ? getDriverName(user) : undefined
  metadata.userEmail = user?.email ?? undefined
}

export const clearApiMetadata = (): void => {
  metadata = {}
}

export const API_HEADERS = {
  USER_ID: 'x-user-id',
  USER_NAME: 'x-user-name',
  USER_EMAIL: 'x-user-email',
} as const

export async function callLocalAPI<T = unknown>(
  url: string,
  method?: 'GET',
): Promise<
  { ok: true; data: T } | { ok: false; error: string; detail?: string }
>
export async function callLocalAPI<T = unknown>(
  url: string,
  method: 'POST',
  body?: RequestInit['body'],
): Promise<
  { ok: true; data: T } | { ok: false; error: string; detail?: string }
>
export async function callLocalAPI<T>(
  url: string,
  method: 'GET' | 'POST' = 'GET',
  body?: RequestInit['body'],
): Promise<
  { ok: true; data: T } | { ok: false; error: string; detail?: string }
> {
  const fetchInit: RequestInit = {
    headers: {
      [API_HEADERS.USER_ID]: encodeURIComponent(metadata.userId ?? '-'),
      [API_HEADERS.USER_NAME]: encodeURIComponent(metadata.userName ?? '-'),
      [API_HEADERS.USER_EMAIL]: encodeURIComponent(metadata.userEmail ?? '-'),
    },
    method,
    body,
  }

  leaveBreadcrumb('API Request', { request: fetchInit }, 'request')
  const response = await fetch(url, fetchInit)

  if (!response.ok) {
    try {
      const json: ApiErrorResponseFormat = await response.json()
      const data: { ok: false; error: string; detail?: string } = {
        ok: false,
        error: response.statusText.trim() || 'Unknown error',
        detail: json.error,
      }
      leaveBreadcrumb('API Response Failed', { url, response: data }, 'error')
      return data
    } catch (error) {
      const data: { ok: false; error: string; detail?: string } = {
        ok: false,
        error: response.statusText.trim() || 'Unknown error',
      }
      leaveBreadcrumb('API Response Failed', { url, response: data }, 'error')
      return data
    }
  }

  const json = (await response.json()) as ApiSuccessResponseFormat<T>
  const result: { ok: true; data: T } = { ok: true, data: json.data }
  leaveBreadcrumb('API Response Success', { url, response: result }, 'request')
  return result
}
