import { randomString, removeToast, showToastNotification } from '@wise/utils'
import { t } from 'i18next'
import React from 'react'

import Loading from '~shared/components/Loading/Loading'
import { notify } from '~shared/services/bugsnag/client'
import { trpcProxy } from '~shared/services/trpc'
import { toLowerCase } from '~shared/utils/toLowerCase'

import { AsyncDownloadType } from './types'
import {
  AsyncDownloadData,
  AsyncDownloadFailType,
  AsyncDownloadOutcomeType,
  AsyncDownloadSchema,
  AsyncDownloadSchemaSuccess,
  AsyncDownloadSuccessType,
} from './validator'

type PushedToastQueue = {
  toastId: string
  type: AsyncDownloadType
}[]

const isSuccessType = (
  data: AsyncDownloadSchema,
): data is AsyncDownloadSchemaSuccess => data.type.endsWith('_GENERATED')

const underlyingType = (type: AsyncDownloadOutcomeType): AsyncDownloadType =>
  type.replace(/_(GENERATED|FAILED)$/, '') as AsyncDownloadType

export class AsyncDownloads {
  private static queue: PushedToastQueue = []

  public static onDisconnect() {
    // Delete all pending toasts if disconnected, as we will never receive the events!
    this.queue.forEach((item) => {
      removeToast(item.toastId)
    })
    this.queue = []
  }

  public static handler(
    log: (...args: unknown[]) => void,
    data: AsyncDownloadSchema,
  ) {
    log(`Handling "new-toast" event with type of "${data.type}"`)

    const resultType = underlyingType(data.type)
    const firstIndex = this.queue.findIndex((i) =>
      i.type.startsWith(resultType),
    )

    // We couldn't find a pending toast for this type, so we don't want to do anything
    if (firstIndex === -1) {
      return
    }

    // Otherwise remove it from the queue, and handle the toast
    const item = this.queue[firstIndex]
    removeToast(item.toastId)
    this.queue.splice(firstIndex, 1)
    log('Removed pending toast from queue', item.toastId)

    // Get the underlying type of the async download we want
    if (isSuccessType(data)) {
      return this.fileGenerationSuccessful(resultType, data.data)
    } else {
      return this.fileGenerationFailed(resultType, data.data)
    }
  }

  private static showPendingToast(type: AsyncDownloadType) {
    const pendingToastId = `download-${type}-${randomString()}`
    const typeAsLowercase = toLowerCase(type)
    showToastNotification({
      id: pendingToastId,
      type: 'custom',
      duration: Infinity,
      description: (
        <div className='flex max-w-350 flex-row items-center gap-3'>
          <Loading variant='small' className='mr-3' />
          <div className='flex flex-col'>
            <p className='font-bold'>
              {t(`async_downloads.${typeAsLowercase}.pending.title`)}
            </p>
            <p className='text-xs font-light'>
              {t(`async_downloads.${typeAsLowercase}.pending.description`)}
            </p>
          </div>
        </div>
      ),
    })
    return pendingToastId
  }

  private static fileGenerationSuccessful(
    type: AsyncDownloadType,
    data: AsyncDownloadData<AsyncDownloadSuccessType>,
  ) {
    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AsyncDownloadSuccess',
      properties: {
        traceId: data.traceId,
        type,
      },
    })
    const id = `${type}-success-${randomString()}`
    const typeAsLowercase = toLowerCase(type)
    showToastNotification({
      id,
      type: 'success',
      description: (
        <div className='flex max-w-350 flex-row items-center gap-3'>
          <p className='font-bold'>
            {t(`async_downloads.${typeAsLowercase}.success.title`)}
          </p>
          <a
            href={data.url}
            download={true}
            type='button'
            target='_blank'
            rel='noopener noreferrer'
            data-testid='download-spreadsheet-button'
            className='rounded-full bg-theme px-3 py-1 font-bold text-white'
            onClick={() => {
              void removeToast(id)
              trpcProxy.services.mixpanel.trackEvent.mutate({
                name: 'AsyncDownloadOpened',
                properties: {
                  traceId: data.traceId,
                  type,
                },
              })
            }}
          >
            {t(`async_downloads.${typeAsLowercase}.success.button`)}
          </a>
        </div>
      ),
      duration: Infinity,
    })
  }

  private static fileGenerationFailed(
    type: AsyncDownloadType,
    data: AsyncDownloadData<AsyncDownloadFailType>,
  ) {
    const typeAsLowercase = toLowerCase(type)
    notify({
      name: 'AsyncDownload.fileGenerationFailed',
      type,
      data,
    })
    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AsyncDownloadError',
      properties: {
        type,
        traceId: data.traceId,
        reason: `FailureResponse`,
      },
    })
    showToastNotification({
      type: 'error',
      description: (
        <div className='flex max-w-350 flex-col'>
          <p className='font-bold'>
            {t(`async_downloads.${typeAsLowercase}.failed.title`)}
          </p>
          <p className='text-xs font-light'>
            {t(`async_downloads.${typeAsLowercase}.failed.description`)}
          </p>
          <p className='text-xs font-light'>{'ID: ' + data.traceId}</p>
        </div>
      ),
    })
  }

  private static timeout(toastId: string, type: AsyncDownloadType) {
    const index = this.queue.findIndex((i) => i.toastId === toastId)
    if (index === -1) return
    const item = this.queue[index]
    const typeAsLowercase = toLowerCase(type)
    removeToast(item.toastId)
    notify({
      name: 'AsyncDownload.timeout',
      type,
    })
    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AsyncDownloadError',
      properties: {
        type,
        reason: `TimedOut`,
      },
    })
    showToastNotification({
      type: 'error',
      description: (
        <div className='flex max-w-350 flex-row items-center gap-3'>
          <div className='flex flex-col pl-3'>
            <p className='font-bold'>
              {t(`async_downloads.${typeAsLowercase}.timeout.title`)}
            </p>
            <p className='text-xs font-light'>
              {t(`async_downloads.${typeAsLowercase}.timeout.description`)}
            </p>
          </div>
        </div>
      ),
    })
  }

  public static startWaiting(
    type: AsyncDownloadType,
    options?: { timeout?: number },
  ) {
    const toastId = this.showPendingToast(type)
    this.queue.push({ toastId, type })

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AsyncDownloadStarted',
      properties: {
        type,
      },
    })

    const timeout = options?.timeout
    if (timeout !== undefined) {
      setTimeout(() => {
        this.timeout(toastId, type)
      }, timeout)
    }
  }
}
