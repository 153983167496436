import * as React from 'react'

import { useUser } from '~shared/services/firebase/auth/hooks'
import { AuthUserData } from '~shared/services/firebase/auth/types'
import { isBranded } from '~shared/utils/brand'

export const getMainContractorFromUser = (user: AuthUserData | undefined) =>
  user && isBranded(user, 'main-contractor') ? user.mainContractor : null

export type MainContractorFromUser = ReturnType<
  typeof getMainContractorFromUser
>

export type UseMainContractorReturn = ReturnType<typeof useMaincontractor>
export const useMaincontractor = () => {
  const user = useUser()

  const maincontractor = React.useMemo(
    () => getMainContractorFromUser(user),
    [user],
  )

  return maincontractor ?? null
}
