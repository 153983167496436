import { ParsedFeatureFlags } from '@wise/kv'
import produce from 'immer'
import create from 'zustand'

import { AuthUserData } from './types'

export type AuthData = {
  accessToken: string
  featureFlags: ParsedFeatureFlags
  user: AuthUserData
}

export type AuthStore = {
  data: AuthData | null
  lastUpdated: Date
  latch: {
    enabled: boolean
    lock: () => void
    release: () => void
  }
  reset: () => void
  resolved: boolean
  setData: (data: AuthData | null) => void
}

const useAuthStore = create<AuthStore>((set) => ({
  data: null,
  lastUpdated: new Date(),
  latch: {
    enabled: false,
    lock: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.latch.enabled = true
        }),
      ),
    release: () =>
      set((state) =>
        produce(state, (draft) => {
          draft.latch.enabled = false
        }),
      ),
  },
  reset: () =>
    set((state) =>
      produce(state, (draft) => {
        draft.data = null
        draft.latch.enabled = false
        draft.lastUpdated = new Date()
      }),
    ),
  resolved: false,
  setData: (data) =>
    set((state) =>
      produce(state, (draft) => {
        draft.data = data
        draft.resolved = true
        draft.lastUpdated = new Date()
      }),
    ),
}))

export default useAuthStore
