import {
  CAN_VIEW_DEBUG,
  CAN_VIEW_FEATURE_FLAG,
  CAN_VIEW_INCIDENT,
  CAN_VIEW_PAYMENTS,
  CAN_VIEW_SCHEDULING,
} from '~shared/hooks/gatekeeper/checks'

import M from './middlewares/alias'
import { MiddlewareMap } from './types'

export const APPLICATION_MIDDLEWARE_MAP: MiddlewareMap = {
  // NAP routes
  '/resource/direct/available': [M.NAP],
  '/resource/direct/onboarding': [M.NAP],
  '/resource/direct/offboarded': [M.NAP],
  '/resource/partners/available': [M.NAP],
  '/resource/partners/onboarding': [M.NAP],
  '/resource/partners/disengaged': [M.NAP],

  // WAP routes
  '/customers': [M.WAP, M.Permissions.Wise.Customer.List],
  '/customers/[id]': [M.WAP, M.Permissions.Wise.Customer.Get],
  '/debug/app-config': [M.WAP, M.gatekeeper({ check: CAN_VIEW_FEATURE_FLAG })],
  '/debug/dev': [M.WAP, M.gatekeeper({ check: CAN_VIEW_DEBUG })],
  '/debug/feature-flags': [
    M.WAP,
    M.gatekeeper({ check: CAN_VIEW_FEATURE_FLAG }),
  ],
  '/debug/maintenance': [M.WAP, M.gatekeeper({ check: CAN_VIEW_DEBUG })],
  '/evidence-template/labels': M.WAP,
  '/evidence-template/new': M.WAP,
  '/evidence-template/edit/[id]': M.WAP,
  '/evidence-template/templates': M.WAP,
  '/main-contractors': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/onboarding': [
    M.WAP,
    M.Permissions.Wise.MainContractor.List,
  ],
  '/main-contractors/archived': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/inactive': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/invite': [M.WAP, M.Permissions.Wise.MainContractor.List],
  '/main-contractors/[mcId]': [M.WAP, M.Permissions.Wise.MainContractor.Get],
  '/main-contractors/[mcId]/driving-licence-checks': [
    M.WAP,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/depots': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library/uploaded': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/document-library/archived': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/documents/[documentId]': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/import-drivers': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/onboarding': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/rate-cards': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/rate-cards/configurator': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/carrier-settings': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/main-contractors/[mcId]/payments': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/payments/details': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/main-contractors/[mcId]/payments/payment-caps': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.Default,
  ],
  '/main-contractors/[mcId]/users': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
  ],
  '/payments/[mcId]/[depotId]': [
    M.WAP,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
    M.Modulr.Default,
  ],
  '/payments/closed': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/completed': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/in-progress': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/payments/submitted': [M.WAP, M.gatekeeper({ check: CAN_VIEW_PAYMENTS })],
  '/users': M.WAP,

  // MCP-only routes
  '/payments/depot/[depotId]': [
    M.MCP,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
    M.Modulr.Default,
  ],
  '/settings': [
    M.MCP,
    M.Permissions.Own.MainContractor.Get,
    M.MCOnboarding.HasPassedKYC,
  ],
  '/settings/users': [
    M.MCP,
    M.Permissions.User.List,
    M.MCOnboarding.HasPassedKYC,
  ],
  '/settings/payments': [M.MCP, M.MainContractor.Active],
  '/settings/payment-caps': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.Default,
  ],
  '/settings/rate-cards': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/settings/carrier-settings': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/settings/driving-licence-checks': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
  ],

  //MCP-onboarding-only routes
  '/settings/document-library': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.HasPassedKYC,
  ],

  // Authenticated routes (MCP or WAP)
  '/': [M.Authenticated, M.MCOnboarding.HasPassedKYC],
  '/compliance/evidence': [
    M.FeatureFlag.ExpiryHubEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/compliance/driving-licence': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
    M.FeatureFlag.DrivingLicenceChecksEnabled,
  ],
  '/drivers/[id]': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/vehicle': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/engagement-details': [
    M.Authenticated,
    M.MCP,
    M.MainContractor.Active,
  ],
  '/drivers/[id]/engagements/[mcId]': [M.Authenticated, M.WAP],
  '/drivers/[id]/financial-details': [M.Authenticated, M.MainContractor.Active],
  '/drivers/[id]/incidents': [
    M.Authenticated,
    M.gatekeeper({ check: CAN_VIEW_INCIDENT }),
    M.MainContractor.Active,
    M.FeatureFlag.IncidentsEnabled,
    M.MCHasDeductionsEnabled.AllowedOnWap,
  ],
  '/drivers/[id]/payments/[summaryId]': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Modulr.Default,
  ],
  '/drivers/[id]/payments/create/[payrunId]': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Modulr.Default,
  ],
  '/drivers/active': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/drivers/inactive': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/drivers/onboarding': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/knowledge-base': [M.Authenticated, M.MainContractor.Active],
  '/knowledge-base/[...category]': [M.Authenticated, M.MainContractor.Active],
  '/payments': [
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
    M.Modulr.Default,
  ],
  '/payments/payrun/[id]': [
    M.Authenticated,
    M.gatekeeper({ check: CAN_VIEW_PAYMENTS }),
  ],
  '/reports': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/awaiting-activation': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/driver-depots': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/signed-policies': [
    M.FeatureFlag.SignedPoliciesReportEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/slas': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/unresponsive-applications': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/utr-report': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/reports/deductions-overview': [
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
    M.FeatureFlag.DeductionsEnabled,
    M.MCHasDeductionsEnabled.AllowedOnWap,
  ],
  '/reports/payable-drivers': [
    M.WAP,
    M.Authenticated,
    M.MainContractor.Active,
    M.Permissions.User.List,
  ],
  '/scheduling': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/[mcId]/[depotId]': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/route-library/[mcId]/[depotId]': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],
  '/scheduling/[mcId]/[depotId]/start-times': [
    M.FeatureFlag.SchedulingEnabled,
    M.Authenticated,
    M.MainContractor.Active,
    M.gatekeeper({ check: CAN_VIEW_SCHEDULING }),
  ],

  /**
   * Main Contractor Onboarding Tool routes
   */

  // Group 1 - Unauthenticated, needs code
  '/onboarding/welcome': [M.Guest, M.MCOnboarding.Stages.CreateAccount],
  '/onboarding/create-account': [M.Guest, M.MCOnboarding.Stages.CreateAccount],

  // Group 2 - Authenticated, needs MC Onboarding Data but no mainContractor
  '/onboarding/about-wise': [M.MCP, M.MCOnboarding.Stages.SetupCompany],
  '/onboarding/about-your-business': [
    M.MCP,
    M.MCOnboarding.Stages.SetupCompany,
  ],

  // Group 3 - Authenticated, needs MC onboarding, MC, but no signature
  '/onboarding/protecting-your-business': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.Contracts,
  ],
  '/onboarding/contracts-and-policies': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.Contracts,
  ],

  // Group 4 - Authenticated, MC onboarding, MC, signature, but modulr status pending
  '/onboarding/checking-details': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.ModulrHold,
  ],

  // Group 5 - Authenticated, MC onboarding, MC, signature, modulr status active
  '/onboarding/finished': [
    M.MCP,
    M.MainContractor.Onboarding,
    M.MCOnboarding.Stages.ModulrSuccess,
  ],

  // Worklog routes
  '/worklog': [
    M.Authenticated,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/worklog/[id]/[locationId]/history': [
    M.WAP,
    M.Permissions.Wise.MainContractor.Get,
    M.FeatureFlag.WorklogEnabled,
  ],
  '/worklog/[id]/history': [
    M.MCP,
    M.MainContractor.Active,
    M.FeatureFlag.WorklogEnabled,
  ],

  // Guest routes
  '/auth/verify': M.Guest,
  '/forgot-password': M.Guest,
  '/login': M.Guest,

  // Unprotected routes
  '/404': null,
  '/auth/action': null,
  '/logout': null,
  '/auth/reset-password': null,
  '/auth/change-password': null,
}
