import * as React from 'react'

import { useAuth } from '~shared/services/firebase/auth/hooks'

import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

interface Props {
  fadeTimeout?: number
}

const AuthOverlay: React.FC<Props> = React.memo<Props>(
  ({ children, fadeTimeout = 500 }) => {
    const { resolved } = useAuth()

    const indeterminate = React.useMemo(() => !resolved, [resolved])

    const [showOverlay, setShowOverlay] = React.useState(() =>
      fadeTimeout === 0 ? indeterminate : true,
    )

    React.useEffect(() => {
      if (indeterminate === false && showOverlay === true && fadeTimeout > 0) {
        const timeout = setTimeout(() => setShowOverlay(false), fadeTimeout)

        return () => clearTimeout(timeout)
      }

      if (indeterminate) setShowOverlay(true)
    }, [fadeTimeout, showOverlay, indeterminate])

    return (
      <>
        {children}
        <LoadingOverlay enabled={showOverlay} />
      </>
    )
  },
)
AuthOverlay.displayName = 'AuthOverlay'

export default AuthOverlay
