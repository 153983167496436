import { isDefined } from '@wise/utils'
import {
  getAnalytics,
  logEvent,
  setCurrentScreen,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'

import { FailedInductionOption } from '~shared/consts/failed'
import { PortalMode } from '~shared/hooks/useMode'
import { isClientSide } from '~shared/services/context'
import { AuthUserData } from '~shared/services/firebase/auth/types'

import { MutateTaskAnalyticsEvent } from '@/applications/hooks/useMutateTask'
import { getDriverName } from '@/subcontractors/tables/helpers'

import { getFirebaseInstance } from '../firebase/firebase'

const analytics = () => getAnalytics(getFirebaseInstance())

export function trackAnalyticsEvent(
  event: 'driver_offboarded',
  data: {
    user_id: string
    main_contractor_id: string
    customer_id: string
    fully_offboarded: boolean
  },
): void
export function trackAnalyticsEvent(
  event: 'driver_failed_application',
  data: {
    user_id: string
    main_contractor_id: string
    customer_id: string
    failure_stage: 'non_starter' | 'background-check' | 'induction' | 'unknown'
    failure_reason: FailedInductionOption['value'] | 'n/a'
  },
): void
export function trackAnalyticsEvent(
  event: 'driver_invite_sent',
  data: {
    auth_user_id: string
    role: string
    mode: string
    main_contractor_id: string
    location_id: string
    was_inactive: boolean
  },
): void
export function trackAnalyticsEvent(
  event: 'user_session_started',
  data: { auth_user_id: string; role: string; mode: PortalMode },
): void
export function trackAnalyticsEvent(
  event: 'user_logged_in',
  data: { firebase_id: string; mode: PortalMode },
): void
export function trackAnalyticsEvent(
  event: 'dashboard_tile_clicked',
  data: {
    auth_user_id: string
    main_contractor_id: string
    mode: PortalMode
    tile_name: string
  },
): void
export function trackAnalyticsEvent(
  event: MutateTaskAnalyticsEvent,
  data: {
    auth_user_id: string
    main_contractor_id: string
    task_name: string
    location: string
  },
): void
export function trackAnalyticsEvent(
  event: 'evidence_approved',
  metadata: {
    auth_user_id: string
    driver_id: string
    hours_taken_to_approve: number
    main_contractor_id: string
  },
): void
export function trackAnalyticsEvent(
  event: 'driver_reinvited',
  metadata: {
    user_id: string
    main_contractor_id: string
    driver_id: string
    application_id: string
  },
): void
export function trackAnalyticsEvent(
  event: string,
  metadata?: Record<string, unknown>,
): void {
  if (!isClientSide()) return

  // Firebase Analytics
  logEvent(analytics(), event, metadata)
}

export function setAnalyticsUser(user: AuthUserData | null): void {
  if (!isClientSide()) return

  // Firebase Analytics
  setUserId(analytics(), user?.id ?? null)
  setUserProperties(analytics(), {
    name: user ? getDriverName(user) : null,
    role: user ? user.roles.find(isDefined)?.title ?? 'unknown' : null,
    mode: user?.__brand ?? null,
  })
}

export function setAnalyticsScreen(screenName: string): void {
  if (!isClientSide()) return

  // Firebase Analytics
  setCurrentScreen(analytics(), screenName)
}
