import { WISE_SALES_ADMIN_ROLE } from './roles'
import { GatekeeperCheck } from './useGatekeeper'

const isNotWiseSalesAdmin: GatekeeperCheck = {
  role: { NOT: WISE_SALES_ADMIN_ROLE },
}

export const CAN_INSTANT_PAY: GatekeeperCheck = {
  userPermission: 'payrun.pay',
}
export const CAN_VIEW_SCHEDULING: GatekeeperCheck = isNotWiseSalesAdmin
export const CAN_ADD_MC_USERS: GatekeeperCheck = isNotWiseSalesAdmin
export const CAN_VIEW_PAYMENTS_DASHBOARD: GatekeeperCheck = {
  AND: [isNotWiseSalesAdmin, { userPermission: 'payruns.dashboard' }],
}

export const CAN_VIEW_PAYMENTS: GatekeeperCheck = {
  AND: [
    isNotWiseSalesAdmin,
    {
      OR: [
        { userPermission: 'payruns.dashboard' },
        { userPermission: 'payrun.view' },
      ],
    },
  ],
}

export const CAN_VIEW_USERS_LIST: GatekeeperCheck = {
  OR: [
    { legacyPermission: 'wise.user.list' },
    { legacyPermission: 'mainContractor.user.list' },
  ],
}

export const CAN_VIEW_MAINCONTRACTORS_LIST: GatekeeperCheck = {
  legacyPermission: 'wise.mainContractor.list',
}

export const CAN_VIEW_CUSTOMERS_LIST: GatekeeperCheck = {
  legacyPermission: 'wise.customer.list',
}

export const CAN_DELETE_PAYRUNS: GatekeeperCheck = {
  userPermission: 'payrun.delete',
}

export const CAN_SET_PAYMENT_START_DATE: GatekeeperCheck = {
  userPermission: 'payrun_start_date.create',
}

export const CAN_OFFBOARD_DRIVERS: GatekeeperCheck = {
  userPermission: 'user.offboard',
}

export const CAN_MARK_APPLICATION_AS_NON_STARTER: GatekeeperCheck = {
  userPermission: 'application.action.non-starter',
}

export const CAN_APPROVE_REJECT_EVIDENCES: GatekeeperCheck = {
  userPermission: 'application.action',
}

export const CAN_PASS_FAIL_BG_TOX_TASKS: GatekeeperCheck = {
  userPermission: 'application.action',
}

export const CAN_PASS_FAIL_INDUCTION: GatekeeperCheck = {
  userPermission: 'induction.action',
}

export const CAN_VIEW_DEBUG: GatekeeperCheck = {
  userPermission: `wise.debug`,
}

export const CAN_VIEW_BACK_OFFICE: GatekeeperCheck = {
  userPermission: `wise.back-office`,
}

export const CAN_VIEW_FEATURE_FLAG: GatekeeperCheck = {
  userPermission: `wise.feature-flag`,
}

export const CAN_DOWNLOAD_SC_INVOICE_BUNDLE: GatekeeperCheck = {
  OR: [
    // Wise user, and has the USER_INVOICES permission
    {
      AND: [{ userType: 'WISE' }, { userPermission: 'user.invoices' }],
    },
    // MC user, and has the MC INVOICES VIEW permission
    {
      AND: [
        { userType: 'MAINCONTRACTOR' },
        { userPermission: 'maincontractor.invoices.view' },
      ],
    },
  ],
}

export const CAN_VIEW_VAT_HISTORY_LOG: GatekeeperCheck = {
  userPermission: 'tax_details_history.view',
}

/**
 * Incidents permissions
 * */
export const CAN_CREATE_INCIDENT: GatekeeperCheck = {
  userPermission: 'incident.create',
}
export const CAN_VIEW_INCIDENT: GatekeeperCheck = {
  userPermission: 'incident.view',
}
export const CAN_UPDATE_INCIDENT: GatekeeperCheck = {
  userPermission: 'incident.update',
}
export const CAN_DELETE_INCIDENT: GatekeeperCheck = {
  userPermission: 'incident.delete',
}
export const CAN_ACCESS_INCIDENTS_OPTIONS: GatekeeperCheck = {
  OR: [
    { userPermission: 'incident.view' },
    { userPermission: 'incident.update' },
    { userPermission: 'incident.delete' },
  ],
}

/**
 * Deductions permissions
 */
export const CAN_CREATE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'deduction.create',
}

export const CAN_CREATE_DEDUCTION_INSTALMENT: GatekeeperCheck = {
  userPermission: 'deduction.instalment.create',
}

export const CAN_DELETE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'deduction.delete',
}

export const CAN_VIEW_DEDUCTION: GatekeeperCheck = {
  userPermission: 'deduction.view',
}

export const CAN_UPDATE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'deduction.update',
}

export const CAN_MIGRATE_SECURITAX_PRODUCT_TO_BEAMIN: GatekeeperCheck = {
  userPermission: 'wise.user.product.securitax_beamin_migrator',
}

export const CAN_EDIT_EXTERNAL_ID: GatekeeperCheck = {
  userPermission: 'engagement.action',
}
