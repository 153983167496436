import type {
  PublicRuntimeConfig,
  RuntimeConfig,
  ServerRuntimeConfig,
} from '@wise/config'
import getConfig from 'next/config'

type RequiredValues<T> = { [P in keyof T]-?: Exclude<T[P], undefined> }

const DEFAULT_PUBLIC_CONFIG: RequiredValues<PublicRuntimeConfig> = {
  appDynamicLink: '',
  bugsnagClientKey: '',
  configServiceUrl: '',
  developerMode: '',
  environment: 'local',
  firebaseConfig: '',
  googleTagManagerKey: '',
  graphqlEndpoint: '',
  portalRuntime: '',
  sanityDataset: '',
  sanityProjectId: '',
  uploadEndpoint: '',
  websocketEndpoint: '',
}

const DEFAULT_SERVER_CONFIG: RequiredValues<ServerRuntimeConfig> = {
  bugsnagServerKey: '',
  mixpanelProjectKey: '',
  redisUrl: '',
}

const result = getConfig() as Optional<RuntimeConfig>
const publicRuntimeConfig: RequiredValues<PublicRuntimeConfig> = {
  ...DEFAULT_PUBLIC_CONFIG,
  ...result?.publicRuntimeConfig,
}
const serverRuntimeConfig: RequiredValues<ServerRuntimeConfig> = {
  ...DEFAULT_SERVER_CONFIG,
  ...result?.serverRuntimeConfig,
}

export { serverRuntimeConfig, publicRuntimeConfig }
