import { noop } from 'lodash'
import Router from 'next/router'
import * as React from 'react'

import { getNextRoute } from '~shared/middleware/nextRoute'

import { resolveAuthData } from './auth'
import { Authenticator } from './Authenticator'
import useAuthStore, { AuthData, AuthStore } from './store'

export type AuthContextType = AuthStore & {
  refresh: () => Promise<AuthData | null>
}

export const AuthContext = React.createContext<AuthContextType>({
  data: null,
  lastUpdated: new Date(),
  latch: {
    enabled: false,
    lock: noop,
    release: noop,
  },
  refresh: async () => null,
  resolved: false,
  setData: noop,
  reset: noop,
})

export const AuthProvider: React.FC = ({ children }) => {
  const store = useAuthStore()

  React.useEffect(() => {
    // Listen to firebase auth session state changes
    const unsubscribe = Authenticator.onSessionTokenChange(
      async (firebaseUser) => {
        const latchEnabled = useAuthStore.getState().latch.enabled
        if (latchEnabled) return
        try {
          const authData = await resolveAuthData(firebaseUser)
          useAuthStore.getState().setData(authData)
        } catch (error) {
          console.error(error)
          const asPath = Router.asPath
          await Authenticator.logout()
          useAuthStore.getState().reset()
          Router.push(
            getNextRoute({
              authState: useAuthStore.getState(),
              requestedUrl: asPath,
            }) ?? '/login',
          )
        }
      },
    )

    // Refresh token if returning to the tab, as we MAY have missed a token change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        Authenticator.refreshToken()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      unsubscribe?.()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const value = React.useMemo<AuthContextType>(() => {
    return {
      ...store,
      refresh: async () => {
        // Refresh our token
        await Authenticator.refreshToken()
        // Get the user
        const firebaseUser = Authenticator.getUser()
        const authData = await resolveAuthData(firebaseUser)
        useAuthStore.getState().setData(authData)
        return authData
      },
    }
  }, [store])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
