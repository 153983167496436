/**
 * These types are what are expected thru the "new-toast" event that we
 * receieve through the WebSocket. They SHOULD be suffixed with _GENERATED
 * and _FAILED by backend - and we automatically apply those in the validator
 * and produce the correct types.
 *
 * If backend deviates from that pattern, we need to change how this works! :(
 *
 */
export const asyncDownloadTypes = [
  'PAYMENTS_REPORT',
  'PAYRUN_EXPORT', // Export spreadsheet
  'PAYRUN_BUNDLE', // Download advice note + invoice for MC payrun
  'WISE_INVOICE_BUNDLE', // Download many driver invoices for payrun
  'SC_INVOICE_BUNDLE', // Download single driver invoices across payruns
] as const

export type AsyncDownloadType = (typeof asyncDownloadTypes)[number]
