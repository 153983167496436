import { Location } from '@wise/graphql'
import { isDefined } from '@wise/utils'
import { uniqWith } from 'lodash'

export const dedupeLocations = <
  T extends Partial<Pick<Location, 'id' | 'code' | 'name'>>,
>(
  locations: (T | null | undefined)[],
  options?: { includeName?: boolean },
): T[] =>
  uniqWith(locations.filter(isDefined), (a, b) => {
    if ((a.id || b.id) && a.id === b.id) return true
    if ((a.code || b.code) && a.code === b.code) return true
    if (options?.includeName && (a.name || b.name) && a.name === b.name)
      return true
    return false
  })
