export const maybe = <T>(fn: () => T): T | undefined => {
  try {
    return fn()
  } catch (error) {
    return undefined
  }
}

export const asyncMaybe = async <T>(
  fn: () => Promise<T>,
): Promise<T | undefined> => {
  try {
    return await fn()
  } catch (error) {
    return undefined
  }
}
